<script setup lang="ts">
import type { Schemas } from "#shopware";
import {
  getCmsLayoutConfiguration,
  getBackgroundImageUrl,
} from "@shopware-pwa/helpers-next";
import { resolveCmsComponent } from "@shopware-pwa/composables-next";
import { h } from "vue";

const props = defineProps<{
  content: Schemas["CmsBlock"];
}>();

const DynamicRender = () => {
  const {
    resolvedComponent,
    componentName,
    isResolved,
    componentNameToResolve,
  } = resolveCmsComponent(props.content);

  if (!resolvedComponent) {
    console.error("Component not resolved: " + componentNameToResolve);
    return null;
  }

  if (!isResolved) {
    return h("div", {}, "Problem resolving component: " + componentName);
  }

  const { cssClasses, layoutStyles } = getCmsLayoutConfiguration(props.content);

  const backgroundImage = layoutStyles.backgroundImage
    ? getBackgroundImageUrl(layoutStyles.backgroundImage, props.content)
    : null;

  // Create style props, only including backgroundSize if backgroundImage exists
  const containerStyles = {
    backgroundColor: layoutStyles.backgroundColor,
    backgroundImage: backgroundImage,
    ...(backgroundImage && { backgroundSize: layoutStyles.backgroundSize }),
  };

  // Filter out null or undefined values
  const filteredContainerStyles = Object.fromEntries(
    Object.entries(containerStyles).filter(([_, value]) => value != null),
  );

  // Remove backgroundSize and backgroundImage from layoutStyles
  const {
    backgroundSize,
    backgroundImage: _,
    ...cleanLayoutStyles
  } = layoutStyles;

  const componentProps = {
    content: props.content,
    style: cleanLayoutStyles,
    class: cssClasses,
  };

  const content = h(resolvedComponent, componentProps);

  return Object.keys(filteredContainerStyles).length > 0
    ? h("div", { style: filteredContainerStyles }, content)
    : content;
};
</script>

<template>
  <DynamicRender />
</template>
